import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { AuthRoute } from '@common/auth/guards/auth-route';
import { PricingEngineSetupLayout } from '@common/pricing-engine-setup/pricing-engine-setup-layout';
import PricingEngineReportPage from './pricing-engine-report-page';

export default function PricingEngineRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      // element: (
      //   <ActiveWorkspaceProvider>
      //     <Outlet />
      //   </ActiveWorkspaceProvider>
      // ),
      element: <PricingEngineSetupLayout />,
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="document_requests.view">
              <PricingEngineReportPage />
            </AuthRoute>
          ),
        },
        
        // {
        //   path: 'new',
        //   element: (
        //     <AuthRoute permission="pricing_engines.create">
        //       <NewPricingEnginePage />
        //     </AuthRoute>
        //   ),
        // },
        // {
        //   path: ':pricingEngineId/edit',
        //   element: (
        //     <AuthRoute permission="pricing_engines.update">
        //       <UpdatePricingEnginePage />
        //     </AuthRoute>
        //   ),
        // },
        // {
        //   path: ':pricingEngineId/view',
        //   element: (
        //     <AuthRoute permission="pricing_engines.view">
        //       <ViewPricingEnginePage />
        //     </AuthRoute>
        //   ),
        // },

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
