import React, {useState} from 'react';
import {DateRangeValue} from '@common/ui/forms/input-field/date/date-range-picker/date-range-value';
import {DateRangePresets} from '@common/ui/forms/input-field/date/date-range-picker/dialog/date-range-presets';
import {ReportDateSelector} from '@common/admin/analytics/report-date-selector';
import { Trans } from '@common/i18n/trans';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { PricingEngineHeaderReport } from './pricing-engine-header-report';
import { usePricingEngineReport } from './use-pricing-engine-report';

export default function PricingEngineReportPage() {
  const [dateRange, setDateRange] = useState<DateRangeValue>(() => {
    // This week
    return DateRangePresets[2].getRangeValue();
  });
  const {isLoading, data} = usePricingEngineReport({dateRange});
  const title = <Trans message="Pricing Engine Setup" />;

  return (
    <div className="min-h-full gap-12 overflow-x-hidden p-12 md:gap-24 md:p-24">
      <div className="mb-24 items-center justify-between gap-24 md:flex">
        <StaticPageTitle>{title}</StaticPageTitle>
        <h1 className="mb-24 text-3xl font-light md:mb-0">{title}</h1>
        <ReportDateSelector value={dateRange} onChange={setDateRange} />
      </div>
      <PricingEngineHeaderReport report={data?.headerReport} />
    </div>
  );
}
