import {createContext} from 'react';

export type PricingSidenavStatus = 'open' | 'closed' | 'compact';

export interface PricingContextValue {
  leftSidenavStatus: PricingSidenavStatus;
  setLeftSidenavStatus: (status: PricingSidenavStatus) => void;
  rightSidenavStatus: PricingSidenavStatus;
  setRightSidenavStatus: (status: PricingSidenavStatus) => void;
  isMobileMode: boolean | null;
  leftSidenavCanBeCompact?: boolean;
  name: string;
}

export const PricingLayoutContext = createContext<PricingContextValue>(
  null!
);
