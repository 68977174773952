import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { LoanProductPage, NewLoanProductPage, UpdateLoanProductPage, ViewLoanProductPage } from './loan-products-page';
import { AuthRoute } from '@common/auth/guards/auth-route';
import { PricingEngineSetupLayout } from '@common/pricing-engine-setup/pricing-engine-setup-layout';

export default function LoanProductRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      // element: (
      //   <ActiveWorkspaceProvider>
      //     <Outlet />
      //   </ActiveWorkspaceProvider>
      // ),
      element: <PricingEngineSetupLayout />,
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="loan_products.view">
              <LoanProductPage />
            </AuthRoute>
          ),
        },

        {
          path: 'new',
          element: (
            <AuthRoute permission="loan_products.create">
              <NewLoanProductPage/>
            </AuthRoute>
          ),
        },
        
        
        {
          path: ':loanProductId/edit',
          element: (
            <AuthRoute permission="loan_products.update">
              <UpdateLoanProductPage/>
            </AuthRoute>
          ),
        },
        
        {
          path: ':loanProductId/view',
          element: (
            <AuthRoute permission="loan_products.view">
              <ViewLoanProductPage />
            </AuthRoute>
          ),
        },

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
