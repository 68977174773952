import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import {useForm} from 'react-hook-form';
import {useCheckPoint} from '../requests/use-check-point';
import {CrupdateCheckPointForm} from './crupdate-check-point-form';

import { useCallback, useEffect, useState } from 'react';
import { apiClient, queryClient } from '@common/http/query-client';
import { CrupdateCheckPointLayout } from './crupdate-check-point-layout';
import { useUpdateCheckPoint } from '../requests/use-update-check-point';
import { CheckPoint } from './check-point';
import { useGetCheckPointSettings } from '@app_modules/requests/use-check-point-settings';
import { CheckListPayload } from '@app_modules/requests/use-create-check-point';
import { ProgressCircle } from '@common/ui/progress/progress-circle';
import { slugifyString } from '@common/utils/string/slugify-string';
import { useGetCheckPoint } from '@app_modules/requests/use-get-check-point';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';


export function EditCheckPointPage() {
  const query = useGetCheckPoint();

  if (query.status !== 'success') {
    return <FullPageLoader />;
  }
  
  return <PageContent checkPoint={query.data.check_point}/>;
}

interface PageContentProps {
   checkPoint: CheckPoint;
}
function PageContent({ checkPoint}: PageContentProps) {

  const [defaultValues, setDefaultValues] = useState<any>({});

  defaultValues["name"] = checkPoint.name;
  defaultValues["lender_ids"] = checkPoint.lender_ids;

  if (checkPoint.items) {
    checkPoint.items.forEach((item: any, pindex: number) => {
        if (item.sub_check_points && item.sub_check_points.length > 0) {
          item.sub_check_points.forEach((subItem: any, index: number) => {

            defaultValues[slugifyString(subItem.category!, "_").replaceAll("-", "_")+'_'+slugifyString(subItem.document_type!, "_").replaceAll("-", "_")+'_check'] = subItem.checked;
          });
        }
    });
  }
  const form = useForm<CheckListPayload>({
    defaultValues: defaultValues,
  });

  const updateCheckPoint = useUpdateCheckPoint(checkPoint.id, form);

  const {data: check_point_setting} = useGetCheckPointSettings();

  return (
    <CrupdateCheckPointLayout
      form={form}
      onSubmit={values => {
        updateCheckPoint.mutate(values, {
          onSuccess: response => {
            //setPlainTextToken(response.plainTextToken);
            //queryClient.invalidateQueries({queryKey: ['users']});
            // queryClient.refetchQueries({queryKey: DatatableDataQueryKey(`check-points/${checkPoint.id}/find`)});
          },
        });
      }}
      title={
        <Trans message="Edit :name" values={{name:  checkPoint.name}} />
      }
      isLoading={updateCheckPoint.isPending}
    >
      <UpdateCheckPoint data={check_point_setting} items={checkPoint.items}/>
    </CrupdateCheckPointLayout>
  );
}



export function UpdateCheckPoint({data, items}:{data: any, items: any}) {

  if (data) {
    return (
      <CrupdateCheckPointForm setting={data} items={items}/>
    );
  } else {
    return (<ProgressCircle isIndeterminate aria-label="Loading settings..." />);
  }
}