import {Outlet} from 'react-router-dom';
import {PricingLayout} from '../ui/layout/pricing-layout';
import {PricingContent} from '../ui/layout/pricing-content';
import {PricingSidenav} from '../ui/layout/pricing-sidenav';
import { PricingEngineSetupSidebar } from './pricing-engine-setup-sidebar';
import { DashboardPricingNavbar } from '@app/dashboard/dashboard-pricing-navbar';

export function PricingEngineSetupLayout() {
  return (
    <PricingLayout name="user" leftSidenavCanBeCompact>
      {/* <PricingNavbar size="sm" menuPosition="admin-navbar" /> */}
      <DashboardPricingNavbar menuPosition="admin-navbar"/>
      <PricingSidenav position="left" size="sm">
        <PricingEngineSetupSidebar />
      </PricingSidenav>
      
      {/* <ActiveWorkspaceProvider>
        <Outlet />
      </ActiveWorkspaceProvider> */}

      <PricingContent>
        <div className="bg dark:bg-alt">
          <Outlet />
        </div>
      </PricingContent>
    </PricingLayout>
  );
}
