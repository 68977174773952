import {Navbar, NavbarProps} from '@common/ui/navigation/navbar/navbar';
import React, { useContext, useEffect } from 'react';
import {Button} from '@common/ui/buttons/button';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {useSettings} from '@common/core/settings/use-settings';
import { currencyFormat } from '@common/utils/string/money-format';
import { BoostrapDataContext } from '@common/core/bootstrap-data/bootstrap-data-context';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { reloadBillingUsage, useBillingUsage } from '@app_modules/requests/use-billing-usage';


export interface PricingNavbarProps
  extends Omit<NavbarProps, 'toggleButton'> {
  hideMakePayment?: boolean;
}


export function DashboardPricingNavbar({hideMakePayment, ...props}: PricingNavbarProps) {
  const {
    data: {user, settings},
  } = useContext(BoostrapDataContext);
  

  
  const {data: usage} = useBillingUsage();
  const available = usage?.billing.available;
  const userId = user?.id;

  useEffect(() => {
    window.Echo.channel('makepayment').listen('.completed', (e: any) => {
      if (userId && e.data[userId] != undefined) {
        const amount = e.data[userId];
        if (amount) {
          reloadBillingUsage();
        }
      }
    });
  }, [])

  return (
    <Navbar
      menuPosition="dashboard"
      className="flex-shrink-0 pricing-grid-navbar"
      rightChildren={
        settings.billing.enable && !hideMakePayment ? (
          <>
          <span>Available Balance: {currencyFormat(available)}</span>
          <Button
            variant="outline"
            size="xs"
            color="primary"
            elementType={Link}
            to="/billings/make-payment"
          >
            <Trans message="Make Payment" />
          </Button>
          <Button
            variant="outline"
            size="xs"
            color="primary"
            elementType={Link}
            to="/pricing"
          >
            <Trans message="Upgrade" />
          </Button>
          </>
        ) : (<span>Available Balance: {currencyFormat(available)}</span>)
      }
    />
  );
}
