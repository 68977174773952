import {
  BackendFilter,
  FilterControlType,
  FilterOperator,
} from '@common/datatable/filters/backend-filter';
import {message} from '@common/i18n/message';
import {
  createdAtFilter,
  updatedAtFilter,
} from '@common/datatable/filters/timestamp-filters';

export const LoanApplicationsIndexPageFilters: BackendFilter[] = [
  
  createdAtFilter({
    label: message('Request Date'),
    description: message('Request Date'),
  })
];
