import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { LoanApplication } from '@app_modules/loan-applications/loan-application';

interface Response extends BackendResponse {
  loanApplication: LoanApplication;
}

export interface LoanApplicationPayload {
  name: string;
  // document_category: string;
}

export function useCreateLoanApplication(form: UseFormReturn<LoanApplicationPayload>) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: LoanApplicationPayload) => createLoanApplication(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['loan-applications']});
      queryClient.invalidateQueries({
        queryKey: ['billing-usage'],
      });
      toast(trans(message('Loan Application created')));
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function createLoanApplication(payload: LoanApplicationPayload) {
  return apiClient
    .post<Response>(`loan-applications`, payload)
    .then(r => r.data);
}

