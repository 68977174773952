import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { CheckPoint } from '@app_modules/check-points/check-point';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';
import { CheckListPayload, CheckListPayloadToFormData } from './use-create-check-point';
import { useNavigate } from '@common/utils/hooks/use-navigate';

interface Response extends BackendResponse {
  checkPoint: CheckPoint;
}

export function useUpdateCheckPoint(
  id: string|number,
  form: UseFormReturn<CheckListPayload>,
) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: CheckListPayload) => updateCheckPoint(id, payload),
    onSuccess: async () => {
      await queryClient.refetchQueries({queryKey: DatatableDataQueryKey(`check-points/${id}/view`)});
      await queryClient.refetchQueries({queryKey: DatatableDataQueryKey(`check-points/${id}/find`)});
      toast(trans(message('Check Point updated')));
      navigate('/check-points');
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function updateCheckPoint(
  id: string|number,
  payload: CheckListPayload,
) {
  return apiClient
    .put<Response>(`check-points/${id}`, CheckListPayloadToFormData(payload)).then(r => r.data);
  }