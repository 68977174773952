import React, {Fragment} from 'react';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import {useSettings} from '@common/core/settings/use-settings';
import {useFormContext} from 'react-hook-form';
import { FormSelect } from '@common/ui/forms/select/select';
import { Item } from '@common/ui/forms/listbox/item';
import { useMyLenders } from '@app_modules/admin/lenders/requests/use-my-lenders';
import { Lender } from '@app_modules/lenders/lender';
import { FormChipField } from '@common/ui/forms/input-field/chip-field/form-chip-field';
import { ChipValue } from '@common/ui/forms/input-field/chip-field/chip-field';

export function CrupdateLoanApplicationFields() {

  const {watch, setValue} = useFormContext();

  const {data: mylenders} = useMyLenders();
  const lenders = mylenders?.lenders;

  const watchedLenderIds = watch('lender_ids');

  const val = watchedLenderIds && watchedLenderIds instanceof Object ? [watchedLenderIds] : (watchedLenderIds ? JSON.parse(watchedLenderIds) : []);
  const setInputValue = (items: any) => {
      let vals: any = [];
      items.forEach((item: any) => {
        if (item.id != undefined && item.id.id != undefined) {
          vals.push(item.id);
        } else {
          vals.push(item);
        }
      });
      
      setValue('lender_ids', JSON.stringify(vals), {
        shouldDirty: true,
      });
  };

  return (
    <Fragment>
      <FormChipField
        className="mb-20"
        label={<Trans message="Lenders" />}
        name="lender_ids"
        chipSize="sm"
        value={val}
        onChange={setInputValue}
      >
        {lenders?.map((lender: ChipValue) => (
          <Item value={lender} key={lender.id} textLabel={lender.name}>
            <Trans message={lender.name} />
          </Item>
        ))}
      </FormChipField>

      <FormTextField
        name="name"
        label={<Trans message="Loan Application Name" />}
        className="mb-20"
        required
      />
    </Fragment>
  );
}
