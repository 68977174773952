import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {Trans} from '@common/i18n/trans';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {useForm} from 'react-hook-form';
import {Form} from '@common/ui/forms/form';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {Button} from '@common/ui/buttons/button';
import { LoanApplicationPayload, useCreateLoanApplication } from '@app_modules/requests/use-create-loan-application';
import { CrupdateLoanApplicationFields } from './crupdate-loan-application-fields';

export function CreateLoanApplicationDialog() {
  const {formId, close} = useDialogContext();
  const form = useForm<LoanApplicationPayload>();
  const createLoanApplication = useCreateLoanApplication(form);
  return (
    <Dialog>
      <DialogHeader>
        <Trans message="New Loan Application" />
      </DialogHeader>
      <DialogBody>
        <Form
          id={formId}
          form={form}
          onSubmit={value => {
            createLoanApplication.mutate(value, {
              onSuccess: () => close(),
            });
          }}
        >
          <CrupdateLoanApplicationFields />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close()}>
          <Trans message="Cancel" />
        </Button>
        <Button
          form={formId}
          type="submit"
          variant="flat"
          color="primary"
          disabled={createLoanApplication.isPending}
        >
          <Trans message="Create" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
