import React, {Fragment} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {FormattedDate} from '@common/i18n/formatted-date';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {LoanApplication} from './loan-application';
import {Link} from 'react-router-dom';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {useDeleteLoanApplication} from '@app_modules/admin/loan-applications/requests/use-delete-loan-application';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {LoanApplicationsIndexPageFilters} from './loan-applications-index-page-filters';
import { VisibilityIcon } from '@common/icons/material/Visibility';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { CreateLoanApplicationDialog } from './create-loan-application-dialog';
import { UpdateLoanApplicationDialog } from './update-loan-application-dialog';
import { Lender } from '@app_modules/lenders/lender';
import { useMyLenders } from '@app_modules/admin/lenders/requests/use-my-lenders';

export function LoanApplicationsIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  
  const {data: mylenders} = useMyLenders();
  const lenders = mylenders?.lenders;

  const columnConfig: ColumnConfig<LoanApplication>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-50',
      header: () => <Trans message="ID #" />,
      body:  loanApplication => loanApplication.id,
    },
    {
      key: 'created_at',
      allowsSorting: false,
      maxWidth: 'max-w-110',
      header: () => <Trans message="Created Date" />,
      body: loanApplication => loanApplication.created_at ? moment.utc(loanApplication.created_at).tz(timezone).format('MM/DD/YYYY') :'',
    },
    {
      key: 'last_opened_date',
      allowsSorting: false,
      maxWidth: 'max-w-110',
      header: () => <Trans message="Last Opened Date" />,
      body: loanApplication => loanApplication.last_opened_date ? moment.utc(loanApplication.last_opened_date).tz(timezone).format('MM/DD/YYYY') :''
      
    },
    {
      key: 'order_by',
      allowsSorting: false,
      maxWidth: 'max-w-150',
      header: () => <Trans message="Order By" />,
      body: loanApplication => loanApplication.order_by,
    },
    {
      key: 'name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Loan Application" />,
      body:  loanApplication => loanApplication.name,
    },
    {
      key: 'lender_name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Lender" />,
      body:  loanApplication => {
        const items = Array.isArray(loanApplication.lender_ids) ? loanApplication.lender_ids : JSON.parse(loanApplication.lender_ids);
        return (
          <div>
          {items?.map((item: any) => (
            <span>{lenders ? GetLenderName(item, lenders) : ''}<br/></span>
          ))}
          </div>);
      }
    },
    {
      key: 'file_ref',
      allowsSorting: false,
      maxWidth: 'max-w-150',
      header: () => <Trans message="File Reference" />,
      body: loanApplication => loanApplication.file_ref,
    },
    {
      key: 'actions',
      header: () => <Trans message="Actions" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-130',
      body:  loanApplication => {
        return (
          <Fragment>


            <DialogTrigger type="modal">
              <Tooltip label={<Trans message="Edit template" />}>
                <IconButton className="text-muted">
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <UpdateLoanApplicationDialog loanApplication={loanApplication} />
            </DialogTrigger>

            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/loan-applications/${ loanApplication.id}/view`}
            >
              <VisibilityIcon />
            </IconButton>
            <DeleteLoanApplicationButton  loanApplication={ loanApplication} />
          </Fragment>
        );
        
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="loan-applications"
      title={<Trans message="Loan Applications" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      filters={LoanApplicationsIndexPageFilters}
      // onRowAction={item => {
      //   navigate(`/reports/${item.report_id}/view`);
      // }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No Loan Applications have been created yet" />}
          filteringTitle={<Trans message="No matching Loan Applications" />}
        />
      }
    />
  );
}

export function GetLenderName(item: any, lenders: any) {

  if (item.name != undefined) {
    return item.name;
  }
  return lenders.find((lender: any) => lender.id == item).name;
}

interface DeleteLoanApplicationButtonProps {
  loanApplication: LoanApplication;
}
function DeleteLoanApplicationButton({ loanApplication}: DeleteLoanApplicationButtonProps) {
  const deleteLoanApplication = useDeleteLoanApplication();
  return (
    <DialogTrigger
      type="modal"
      onClose={confirmed => {
        if (confirmed) {
          deleteLoanApplication.mutate({ id:  loanApplication.id});
        }
      }}
    >
      <Tooltip label={<Trans message="Delete Loan Application" />}>
        <IconButton
          size="md"
          className="text-muted"
          disabled={deleteLoanApplication.isPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Delete loanApplication" />}
        body={<Trans message="Are you sure you want to delete this Loan Application?" />}
        confirm={<Trans message="Delete" />}
      />
    </DialogTrigger>
  );
}


function Actions() {
  return (
    <DialogTrigger type="modal">
      <DataTableAddItemButton>
        <Trans message="New Loan Application" />
      </DataTableAddItemButton>
      <CreateLoanApplicationDialog />
    </DialogTrigger>
  );
}