import {useState, useEffect, useCallback, Fragment} from 'react';
import { Trans } from '@common/i18n/trans';
import { slugifyString } from '@common/utils/string/slugify-string';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { CheckPoint } from './check-point';
import { ArrowCircleRightIcon } from '@common/icons/material/ArrowCircleRight';
import { SubCheckList } from './sub-check-list';
import { CheckList } from './check-list';
import { FileItem } from '@app_modules/loan-applications/step1/file-item';
import { PromptAction } from './uw-prompt-check-point-page';
import { UploadDialog } from './uw-document-check-point-page';
import { ResultAction } from './uw-result-check-point-page';
import { useUWCheckPointAction } from '@app_modules/requests/use-uw-check-point-action';
import { ArrowRightAltIcon } from '@common/icons/material/ArrowRightAlt';
import { useAuth } from '@common/auth/use-auth';
import { useParams } from 'react-router-dom';

interface UWCheckPointsPanelProps {
  check_point: CheckPoint;
  fetchCheckPoint: any
}
export function UWCheckPointsPage({
  check_point,
  fetchCheckPoint
}: UWCheckPointsPanelProps) {

  const [id, setId] = useState(0);
  const [reportId, setReportId] = useState(0);
  const [status, setStatus] = useState('');
  
  window.Echo.channel('check-point').listen('.process', (e: any) => {
    if (e.data[checkPoint.id] != undefined) {
      const item = e.data[checkPoint.id];
      if (item.id) {
        setId(item.id);
      }
      if (item.reportId) {
        setReportId(item.reportId);
      }
      if (item.status) {
        setStatus(item.status);
      }
    }
  });

  
  const [checkPoint, setCheckPoint] = useState<CheckPoint>(check_point);
  const { checkPointId} = useParams();

  useEffect(() => {
    if (id || reportId || status) {
      fetchCheckPoint(checkPointId).then(async (response: any) => {
        const item = await response.data.check_point;
        setCheckPoint(item);
      })
    }
  }, [fetchCheckPoint, id, reportId, status]);

  const [checkPointItems, setCheckPointItems] = useState<CheckList[]>(checkPoint.items ? checkPoint.items : []);
  useEffect(() => {
    if (checkPoint.items) {
      setCheckPointItems(checkPoint.items);
    }
  }, [checkPoint]);

  return (
    <>
    
      <table className="w-[100%] table">
        <thead className="border-b sticky top-0 bg-slate-300">
          <tr className='bg-gray-300'>
            <th scope="col" className="w-[4%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            </th>
            <th scope="col" className="w-[9%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Check Point Category" />
            </th>
            <th scope="col" className="w-[9%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Check Point Name" />
            </th>
            <th scope="col" className="w-[9%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Document Type" />
            </th>
            <th scope="col" className="w-[9%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            </th>
            <th scope="col" className="w-[13%] bg-gray-300 text-sm text-gray-900 px-6 py-4 text-left">
              <Trans message="AI Underwriting" />
            </th>
            <th scope="col" className="w-[12%] bg-gray-300 text-sm text-gray-900 px-6 py-4 text-left">
              <Trans message="Result" />
            </th>
          </tr>
        </thead>
        <tbody className="h-30 overflow-y-auto">
          {checkPointItems?.map((item: any, pidx: number) => (
            <SubCheckListMeta key={`check-list-${pidx}`} pidx={pidx} checkPoint={checkPoint} subCheckPoints={item.sub_check_points}/>
          ))}
        </tbody>
      </table>

    </>
  );
}

function SubCheckListMeta({checkPoint, pidx, subCheckPoints}: {checkPoint: CheckPoint, pidx: number, subCheckPoints: SubCheckList[]}) {

  const [subItems, setSubItems] = useState<SubCheckList[]>(subCheckPoints? subCheckPoints : []);

  useEffect(() => {
    setSubItems(subItems);
  }, [subItems]);

  return (
    <>
      {subItems?.map((subItem: SubCheckList, index: number) => (
        <UWSubItem key={`sub-item-${pidx}-${index}`} pidx={pidx} index={index} subItem={subItem} checkPoint={checkPoint}/>
      ))}
    </>
  );
}

export function UWSubItem({pidx, index,subItem, checkPoint}: {pidx: number, index: number, subItem: SubCheckList, checkPoint: CheckPoint}) {
  const [indx, setIndx] = useState(0);
  const loanCategory = slugifyString(subItem.category!, "_").replaceAll("-", "_");
  const documentType = slugifyString(subItem.document_type!, "_").replaceAll("-", "_");
  const [itemStatus, setItemStatus] = useState(checkPoint.fileItems &&  checkPoint.fileItems[loanCategory+"-"+documentType] ? checkPoint.fileItems[loanCategory+"-"+documentType] : {'status': '', 'total_files': 0});

  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const addFiles = (index: number, files: any) => {
    setFileItems([
      {key: '1', 'files': files, document_type: subItem.document_type, 'status': 'Uploaded', 'total_files': files.length},
    ]);
    setItemStatus({'status': 'Uploaded', 'total_files': files.length});
    setIndx(index);
  };

  useEffect(() => {
    setFileItems(fileItems);
  }, [fileItems, indx]);

  useEffect(() => {
    if (checkPoint && checkPoint.fileItems != undefined ) {
      setItemStatus(checkPoint.fileItems &&  checkPoint.fileItems[loanCategory+"-"+documentType] ? checkPoint.fileItems[loanCategory+"-"+documentType]: {'status': '', 'total_files': 0});
    }
  }, [checkPoint]);

  const onProcess = (status: string, total_files: number) => {
    if (total_files) {
      setItemStatus({'status': status, 'total_files': total_files});
      setFileItems([]);
    }
  }

  
  return (
    <tr key={`sub-${pidx}-${index}`} className="border-b transition duration-300 ease-in-out hover:bg-gray-100 w-[100%]">
      {index == 0 ? (
        <>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-l`}>
          <ArrowCircleRightIcon size='lg'/>
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
          {subItem.category}
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-l`}>
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap`}>
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r`}>
        </td>
        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
          <UWAction onProcess={onProcess} key={`action-${pidx}-${index}`} checkPoint={checkPoint} subCheckList={subItem} pindex={pidx} fileItems={fileItems} itemStatus={itemStatus}></UWAction>
        </td>
        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
        {itemStatus && itemStatus.status == 'Processed' ? (
          <ResultAction key={`result-${pidx}-${index}`} checkPoint={checkPoint} subCheckList={subItem} pindex={pidx}/>
        ):(<></>)}
        </td>
        </>
        ): (
        <>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-l`}>
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r`}>
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-l`}>
          {subItem.name}
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
          <ArrowRightAltIcon size='lg' className='text-blue'/>{subItem.document_type}
        </td>
        <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
          <Fragment>
            <DialogTrigger type="modal">
              <Button size={'xs'} variant="outline" color="orange" className='mr-10'>
                <Trans message="Upload" />
              </Button>
              <UploadDialog index={index} document_type={subItem.document_type} addFiles={addFiles}/>
            </DialogTrigger>

            {itemStatus && itemStatus.status ? (
              <><Trans message={itemStatus.status} /> {itemStatus.total_files} files</>
            ): (<></>)}

          </Fragment>
        </td>
        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
          <UWAction onProcess={onProcess} key={`action-${pidx}-${index}`} checkPoint={checkPoint} subCheckList={subItem} pindex={pidx} fileItems={fileItems} itemStatus={itemStatus}></UWAction>
        </td>
        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
        {itemStatus && itemStatus.status == 'Processed' ? (
          <ResultAction key={`result-${pidx}-${index}`} checkPoint={checkPoint} subCheckList={subItem} pindex={pidx}/>
        ):(<></>)}
        </td>
        </>)}
      
    </tr>
  )
}

export function UWAction({
  checkPoint,
  subCheckList,
  pindex,
  fileItems, 
  itemStatus,
  onProcess,
}: {pindex: number, checkPoint: CheckPoint, subCheckList: SubCheckList, fileItems: any, itemStatus: any, onProcess: any}) {

  const loanCategory = slugifyString(subCheckList.category!, "_").replaceAll("-", "_");
  const documentType = slugifyString(subCheckList.document_type!, "_").replaceAll("-", "_");

  const [prompt, setPrompt] = useState(checkPoint.prompts &&  checkPoint.prompts[loanCategory+"-"+documentType] ? checkPoint.prompts[loanCategory+"-"+documentType] : '');

  useEffect(() => {
    if (itemStatus && itemStatus.status != 'Processed') {
      setPrompt('');
    }
  }, [itemStatus]);

  return (
    <div className='flex w-full gap-10'>
      <ExecuteAction onProcess={onProcess} fileItems={fileItems} key={`exe-${pindex}`} id={checkPoint.id} loan_category={subCheckList.category!} document_type={subCheckList.document_type!} prompt={prompt}/>
    </div>
  );
}



type ExecuteActionProps = {
  id: string|number;
  loan_category: string;
  document_type: string;
  prompt: string,
  fileItems: any,
  onProcess: any
};
export function ExecuteAction( {id, loan_category, document_type, prompt, fileItems, onProcess}: ExecuteActionProps) {
  const documentType = slugifyString(document_type, "_").replaceAll("-", "_");
  const loanCategory = slugifyString(loan_category, "_").replaceAll("-", "_");
  const files = fileItems.length > 0 ? fileItems[0]['files'] : [];
  const {user} = useAuth();

  const executeUWAction = useUWCheckPointAction(id, loanCategory, documentType, files);
  return (
    <>
      <Button
        size={'xs'} variant="flat" color="orange"
        type="submit"
        onClick={() => {
          executeUWAction.mutate(
            {id: id, loan_category: loanCategory, document_type: documentType, files: files},
            {
              onSuccess: (response) => {
                onProcess(response.status, response.total_files);
              },

            },
          );
        }}
        disabled={!fileItems.length || executeUWAction.isPending}
        >
        <Trans message={`Check ${document_type}`} />
      </Button>

      {prompt  && user?.id && user.id == 1? (
        <PromptAction prompt={prompt}/>
      ) : (<></>)}
    </>

  );
}







