import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {Trans} from '@common/i18n/trans';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {useForm} from 'react-hook-form';
import {Form} from '@common/ui/forms/form';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {Button} from '@common/ui/buttons/button';
import { LoanApplicationPayload, useUpdateLoanApplication } from '@app_modules/requests/use-update-loan-application';
import { LoanApplication } from './loan-application';
import { CrupdateLoanApplicationFields } from './crupdate-loan-application-fields';

interface Props {
  loanApplication: LoanApplication;
}
export function UpdateLoanApplicationDialog({loanApplication}: Props) {
  const {formId, close} = useDialogContext();
  const form = useForm<LoanApplicationPayload>({
    defaultValues: {
      name: loanApplication.name,
      lender_ids: loanApplication.lender_ids
    },
  });
  const updateLoanApplication = useUpdateLoanApplication(loanApplication.id, form);
  return (
    <Dialog>
      <DialogHeader>
        <Trans message="Update Loan Application" />
      </DialogHeader>
      <DialogBody>
        <Form
          id={formId}
          form={form}
          onSubmit={value => {
            updateLoanApplication.mutate(value, {
              onSuccess: () => close(),
            });
          }}
        >
          <CrupdateLoanApplicationFields />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close()}>
          <Trans message="Cancel" />
        </Button>
        <Button
          form={formId}
          type="submit"
          variant="flat"
          color="primary"
          disabled={updateLoanApplication.isPending}
        >
          <Trans message="Update" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
